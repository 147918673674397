export const FindObjectById = (components: any[], id: string) => {
	for (const component of components) {
		if (component?._id === id) {
			return component;
		} else {
			if (component.children?.length > 0) {
				const result: any = FindObjectById(component.children, id);
				if (result) {
					return result;
				}
			}
		}
	}
}