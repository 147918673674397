import { JourneyInstance, ViewChildren } from "@ciptex-race/journey";

export const UpdateViewComponents = async (journey: JourneyInstance, { viewName, components }: { viewName: string, components: ViewChildren[] }): Promise<JourneyInstance> => {
	return journey.update({
		definition: {
			...journey.definition,
			views: {
				...journey.definition.views,
				[viewName]: {
					...journey.definition.views[viewName],
					components
				}
			}
		}
	});
}