import { useCallback, useEffect, useState } from "react"
import { ALLOWED_IFRAME_ORIGINS } from "../../../constants";

export const useAuthenticate = () => {
	const [auth, setAuth] = useState<Record<string, any>>();

	const onMessage = useCallback((event: any) => {
		if (!ALLOWED_IFRAME_ORIGINS.includes(event.origin) || event.data.source !== "plugin-race-core") {
			return;
		}

		switch (event.data.type) {
			case "Authenticate":
				setAuth(event.data.data);
				break;
			default:
				break;
		}

	}, []);

	useEffect(() => {
		window.addEventListener("message", onMessage, false);
		return () => {
			window.removeEventListener("message", onMessage);
		}
	}, [onMessage]);

	return auth;
}