import { JourneyInstance, JourneyManager, JourneyResource, WriteOnly } from "@ciptex-race/journey";
import { Logger } from "@ciptex-race/logger";

const logger = Logger.getInstance();

export const GetJourney = async (config: WriteOnly<JourneyResource & { sid: string }>): Promise<JourneyInstance> => {
	const manager = JourneyManager.getInstance();
	try {
		logger.debug("GetJourney", `Attempting to retrieve journey for ${config.sid}`);
		const journey = await manager.journeys(config.sid).fetch();
		return journey;
	} catch (error) {
		logger.debug("GetJourney", `Journey not found. Creating journey for ${config.sid}`);
		const journey = await manager.journeys.create(config);
		logger.debug("GetJourney", `Journey created for ${config.sid}`, journey);
		return journey;
	}
}