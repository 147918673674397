import { ActionTemplate, JourneyClient } from "@ciptex/journey-sdk";
import { type JourneyResource, WriteOnly } from "@ciptex-race/journey";
import { ReactElement, createContext, useCallback } from "react";
import { useGetConfig, useGetConfigResponse } from "./hooks/useGetConfig";
import { ActionResource } from "@ciptex-race/journey/dist/sdk/manager/actions/Action";
import { Logger } from "@ciptex-race/logger";
import { useAuthenticate } from "./hooks/useAuthenticate";
import { useStudioContext } from "../../hooks/useStudioContext";

export type ConfigContextType = {
	journeysConfig: useGetConfigResponse<WriteOnly<JourneyResource & { sid: string }>>;
	actionsConfig: useGetConfigResponse<WriteOnly<ActionResource & { sid: string }>>;
	templatesConfig: useGetConfigResponse<ActionTemplate>;
	addJourney: (name: string) => Promise<void>;
	addAction: (name: string) => Promise<void>;
	authData: Record<string, any> | undefined;
}

export const ConfigContext = createContext<ConfigContextType>(null!);

const logger = Logger.getInstance();

export const ConfigProvider = ({ children }: { children: ReactElement }) => {
	const { toaster } = useStudioContext();

	const listJourneys = useCallback(async (auth?: Record<string, any>) => {
		if (!auth) {
			return [];
		}

		try {
			logger.info("ConfigProvider", "Loading Journey Config");
			const client = new JourneyClient({
				accountSid: auth.accountSid,
				token: auth.user.token
			});

			const data = await client.journey.list({ pageSize: 50 }) as (WriteOnly<JourneyResource & { sid: string }>[]);

			return data;
		} catch (error: any) {
			throw logger.error("ConfigProvider", error);
		}
	}, []);

	const listActions = useCallback(async (auth?: Record<string, any>) => {
		if (!auth) {
			return [];
		}

		try {
			logger.info("ConfigProvider", "Loading Actions Config");
			const client = new JourneyClient({
				accountSid: auth.accountSid,
				token: auth.user.token
			});

			const data = await client.action.list({ pageSize: 50 }) as (WriteOnly<ActionResource & { sid: string }>[]);

			return data;
		} catch (error: any) {
			throw logger.error("ConfigProvider", error);
		}
	}, []);

	const listTemplates = useCallback(async (auth?: Record<string, any>) => {
		if (!auth) {
			return [];
		}

		try {
			logger.info("ConfigProvider", "Loading Action Templates Config");
			const client = new JourneyClient({
				accountSid: auth.accountSid,
				token: auth.user.token
			});

			const data = await client.actiontemplate.list({ pageSize: 500 });

			return data;
		} catch (error: any) {
			throw logger.error("ConfigProvider", error);
		}
	}, []);

	const authData = useAuthenticate();
	const journeysConfig = useGetConfig<WriteOnly<JourneyResource & { sid: string }>>({ listFunction: listJourneys, authData });
	const actionsConfig = useGetConfig<WriteOnly<ActionResource & { sid: string }>>({ listFunction: listActions, authData });
	const templatesConfig = useGetConfig<ActionTemplate>({ listFunction: listTemplates, authData });

	const addAction = useCallback(async (name: string) => {
		logger.debug("StateProvider", `addAction ${name}`);

		if (!name || !authData) {
			return;
		}

		const client = new JourneyClient({
			accountSid: authData.accountSid,
			token: authData.user.token
		});

		const newAction = await client.action.create({
			friendlyName: name,
			fnName: "handler",
			code: `const handler = async () => {

            }`
		}, {}) as WriteOnly<ActionResource & { sid: string; }>;

		await actionsConfig.add(newAction, { navigateTo: `action/${newAction.sid}` });

		toaster.push({
			variant: "success",
			message: `Action ${name} created`,
			dismissAfter: 2000
		});
	}, [actionsConfig, authData, toaster]);

	const addJourney = useCallback(async (name: string) => {
		logger.debug("StateProvider", `addJourney ${name}`);

		if (!name || !authData) {
			return;
		}

		const client = new JourneyClient({
			accountSid: authData.accountSid,
			token: authData.user.token
		});

		const newJourney = await client.journey.create({
			friendlyName: name,
			definition: {
				views: {
					"start": {
						components: []
					}
				}
			}
		}, {}) as WriteOnly<JourneyResource & { sid: string; }>;

		await journeysConfig.add(newJourney, { navigateTo: `journey/${newJourney.sid}/start` });

		toaster.push({
			variant: "success",
			message: `Journey ${name} created`,
			dismissAfter: 2000
		});
	}, [authData, journeysConfig, toaster]);

	return <ConfigContext.Provider value={{
		journeysConfig,
		actionsConfig,
		templatesConfig,
		addJourney,
		addAction,
		authData
	}}>{children}</ConfigContext.Provider>;
}
