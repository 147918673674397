import { ActionInstance, ActionResource } from "@ciptex-race/journey";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useActionEditorHook } from "./useActionEditor";

export const useActionMetaData = ({ actionInstance, editor }: { actionInstance?: ActionInstance, editor: useActionEditorHook }) => {
	const [formData, setFormData] = useState<Omit<ActionResource, "code">>({ friendlyName: "", fnName: "", sid: "" } as any);

	const onFormChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		if (!e.target.name) {
			return;
		}
		editor.setIsChanged(true);
		setFormData({ ...formData, [e.target.name]: e.target.value })
	}, [editor, formData]);

	useEffect(() => {
		if (!actionInstance) {
			setFormData({ friendlyName: "", fnName: "", sid: "" } as any);
			return;
		}

		const { friendlyName, fnName, sid } = actionInstance || {};
		setFormData({ friendlyName, fnName, sid })
	}, [actionInstance]);

	return {
		formData,
		onFormChange
	}
}