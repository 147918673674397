import { ContextInstanceTypes, JourneyInstance, ViewResource, WriteOnly, getFromComponentMap } from "@ciptex-race/journey";
import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { FindObjectParentById } from "../../../functions/FindObjectByParentId";
import { Logger } from "@ciptex-race/logger";
import { SetSelectedComponent } from "../StateProvider";
import { UpdateViewComponents } from "../../../functions/UpdateViewComponents";
import { useStudioContext } from "../../../hooks/useStudioContext";

const logger = Logger.getInstance();

interface useMessageEventsHook {
	journey?: JourneyInstance;
	setSelectedComponent: SetSelectedComponent;
	setJourneyInstance: Dispatch<SetStateAction<JourneyInstance | undefined>>;
	activeView: {
		name?: string;
		value?: Omit<WriteOnly<ViewResource>, "name">;
	};
}

export const useMessageEvents = ({ journey, activeView, setSelectedComponent }: useMessageEventsHook) => {
	const { toaster } = useStudioContext();

	const onRemoveComponent = useCallback(async (contextData: ContextInstanceTypes["contextData"] | undefined) => {
		logger.debug("StateProvider", "onRemoveComponent", contextData);

		if (!journey || !activeView?.name || !activeView?.value) {
			return;
		}

		const { _id, _type } = contextData || {};
		const { friendlyName } = getFromComponentMap(_type);

		const { components = [] } = activeView?.value || {};

		if (_type === "view") {
			//
		} else {
			const c = FindObjectParentById(components as any, _id);

			/* eslint-disable no-plusplus */
			for (let i = 0; i < c.length; i++) {
				if (c[i]._id === _id) {
					c.splice(i, 1);
					break;
				}
			}
		}

		try {
			await UpdateViewComponents(journey, {
				viewName: activeView.name,
				components
			});

			toaster.push({
				variant: "success",
				message: `${friendlyName} removed`,
				dismissAfter: 2000
			});

			setSelectedComponent({ component: { type: "view", _id: "view" }, contextType: "view" } as any);
		} catch (error) {
			toaster.push({
				variant: "error",
				message: `Unable to remove ${friendlyName}`,
				dismissAfter: 2000
			});
		}
	}, [activeView.name, activeView.value, journey, setSelectedComponent, toaster]);

	const onMessage = useCallback((event: any) => {
		if (event.origin !== window.location.origin || event.data.source !== "journey-studio-editor") {
			return;
		}

		switch (event.data.type) {
			case "ComponentRemoveClick":
				onRemoveComponent(event.data.data);
				break;
			default:
				break;
		}
	}, [onRemoveComponent]);


	useEffect(() => {
		window.addEventListener("message", onMessage, false);

		return () => {
			window.removeEventListener("message", onMessage);
		}
	}, [onMessage]);
}