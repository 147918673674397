import { JourneyInstance } from "@ciptex-race/journey";
import { Logger } from "@ciptex-race/logger";

const logger = Logger.getInstance();

export const GetViewByName = async (journey: JourneyInstance, { viewName }: { viewName: string }) => {
	logger.debug("ChangeActiveViewByName", viewName);

	const views = await journey.views.list();
	const view = views.find(x => x.name === viewName);
	return view;
}