import { ActionInstance, ActionResource } from "@ciptex-race/journey/dist/sdk/manager/actions/Action";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { GetAction } from "../../../functions/GetAction";
import { Logger } from "@ciptex-race/logger";
import { WriteOnly } from "@ciptex-race/journey";

export interface useActionStateHook {
	actionInstance?: ActionInstance;
	setActionInstance: Dispatch<SetStateAction<ActionInstance | undefined>>
}

interface useLoadActionHook {
	actionsConfig?: WriteOnly<ActionResource & { sid: string }>[];
	actionSid?: string;
}

const logger = Logger.getInstance();

export const useLoadAction = ({ actionsConfig, actionSid }: useLoadActionHook): useActionStateHook => {
	const [actionInstance, setActionInstance] = useState<ActionInstance | undefined>();
	const [isCreating, setIsCreating] = useState<boolean>(false);

	const load = useCallback(async () => {
		logger.debug("useLoadAction", "load", actionSid, actionsConfig);

		if (!actionSid || !actionsConfig) {
			setIsCreating(false);
			return;
		}

		setIsCreating(true);
		const actionConfig = actionsConfig.find(x => x.sid === actionSid);
		if (!actionConfig) {
			setIsCreating(false);
			return;
		}

		const action = await GetAction(actionConfig);
		setActionInstance(action);
		setIsCreating(false);
	}, [actionSid, actionsConfig]);

	useEffect(() => {
		if (isCreating) {
			logger.warn("useLoadJourney", "rejected due to isCreating");
			return;
		}

		load();
	}, [isCreating, load]);

	return {
		setActionInstance,
		actionInstance
	};
}