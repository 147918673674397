import { MouseEvent, useCallback } from "react";
import { SidebarNavigationItem as PasteSidebarNavigationItem, SidebarNavigationItemProps } from "@twilio-paste/core"
import { useHref, useLinkClickHandler } from "react-router-dom";
import { useActionContext } from "hooks/useActionContext";

export const SidebarNavigationItem = ({ href, children, ...rest }: SidebarNavigationItemProps & { href: string }) => {
	const { actionInstance, editor } = useActionContext();
	const newHref: string = useHref(href);
	const handleClick = useLinkClickHandler<HTMLAnchorElement>(href);

	const onClick = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();
		if (actionInstance && editor.isChanged) {
			editor.setPendingAction({ type: "navigate", payload: newHref });
			editor.setIsAlertOpen(true);
		} else {
			editor.setIsAlertOpen(false);
			handleClick(e);
		}
	}, [actionInstance, editor, handleClick, newHref]);

	return (<PasteSidebarNavigationItem onClick={onClick} href={newHref} {...rest}>
		{children}
	</PasteSidebarNavigationItem>)
}