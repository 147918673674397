import { Button } from "@twilio-paste/core/button";
import { ButtonGroup } from "@twilio-paste/core/button-group";
import { DirectoryIcon } from "@twilio-paste/icons/esm/DirectoryIcon";
import { FileIcon } from "@twilio-paste/icons/esm/FileIcon";
import { Tooltip } from "@twilio-paste/core/tooltip";
import { useParams } from "react-router-dom";
import { useStudioContext } from "../../hooks/useStudioContext";

export const SidebarJourneyActions = () => {
	const { sidebar } = useStudioContext();
	const { journeySid } = useParams();

	const onClickNewJourney = () => {
		sidebar.setIsAddingView(false);
		sidebar.setIsAddingJourney(true);
		setTimeout(() => {
			sidebar.newJourneyInputRef.current?.focus();
		}, 50);
	}

	const onClickNewView = () => {
		sidebar.setIsAddingJourney(false);
		sidebar.setIsAddingView(true);
		setTimeout(() => {
			sidebar.newViewInputRef.current?.focus();
		}, 50);
	}

	return (<ButtonGroup>
		{journeySid ? <Tooltip text="New View..." placement="bottom-end">
			<Button variant="inverse" size="reset" onClick={onClickNewView}>
				<FileIcon decorative={false} title="New View" />
			</Button>
		</Tooltip> : <></>}
		<Tooltip text="New Journey..." placement="bottom-end">
			<Button variant="inverse" size="reset" onClick={onClickNewJourney}>
				<DirectoryIcon decorative={false} title="New Journey" />
			</Button>
		</Tooltip>
	</ButtonGroup>);
}