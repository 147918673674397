import { ReactElement, createContext } from "react";
import { UseToasterReturnedProps, useToaster } from "@twilio-paste/core/toast";
import { useDesignerView, useDesignerViewHook } from "./hooks/useDesignerView";
import { useSidebarState, useSidebarStateHook } from "./hooks/useSidebarState";

export type StateContextType = {
	sidebar: useSidebarStateHook;
	designerView: useDesignerViewHook;
	toaster: UseToasterReturnedProps
}

export const StudioContext = createContext<StateContextType>(null!);

export const StudioProvider = ({ children }: { children: ReactElement }) => {
	const sidebar = useSidebarState();
	const designerView = useDesignerView();
	const toaster = useToaster();

	return <StudioContext.Provider value={{ toaster, designerView, sidebar }}>{children}</StudioContext.Provider>;
}
