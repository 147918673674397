import { ChangeEvent, FormEvent, KeyboardEvent, useCallback, useState } from "react";
import { Form, FormControl } from "@twilio-paste/core/form";
import { FileIcon } from "@twilio-paste/icons/esm/FileIcon";
import { HelpText } from "@twilio-paste/core";
import { Input } from "@twilio-paste/core/input";
import { useStateContext } from "../../hooks/useStateContext";
import { useStudioContext } from "../../hooks/useStudioContext";
import { useUID } from "@twilio-paste/core/uid-library";

export const SidebarViewInput = () => {
	const [value, setValue] = useState<string>("");
	const [error, setError] = useState<boolean>(false);
	const { addView, journeyInstance, viewNames } = useStateContext();
	const { sidebar } = useStudioContext();
	const errorMessageUID = useUID();

	const onSubmit = useCallback(async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (!journeyInstance?.sid || error) {
			return;
		}

		addView(journeyInstance?.sid, value);
		sidebar.setIsAddingView(false);
	}, [addView, error, journeyInstance?.sid, sidebar, value]);

	const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
		if (viewNames.includes(e.target.value)) {
			setError(true);
		} else {
			setError(false);
		}
	}, [viewNames]);

	const onKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Escape") {
			sidebar.setIsAddingView(false);
		}
	}, [sidebar]);

	const onBlur = useCallback(() => {
		sidebar.setIsAddingView(false);
	}, [sidebar]);

	return (<Form onSubmit={onSubmit}>
		<FormControl>
			<Input hasError={error} aria-errormessage={errorMessageUID} element="SIDEBAR_INPUT_VIEW" type="text" insertBefore={
				<FileIcon decorative size="sizeIcon30" />
			} value={value} onChange={onChange} onKeyDown={onKeyDown} onBlur={onBlur} ref={sidebar.newViewInputRef} />
			{error && <HelpText id={errorMessageUID} variant="error_inverse">Invalid View Name</HelpText>}
		</FormControl>
	</Form>
	)
}