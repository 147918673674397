import { Sidebar, SidebarBody, SidebarCollapseButton, SidebarFooter, SidebarHeader, SidebarHeaderIconButton, SidebarHeaderLabel, SidebarPushContentWrapper } from "@twilio-paste/core/sidebar";
import { Box } from "@twilio-paste/core/box";
import { DirectoryIcon } from "@twilio-paste/icons/esm/DirectoryIcon";
import { ReactElement } from "react";
import { Separator } from "@twilio-paste/core/separator";
import { SidebarActionActions } from "./SidebarActionActions";
import { SidebarActionInput } from "./SidebarActionInput";
import { SidebarActionNavigation } from "./SidebarActionNavigation";
import { SidebarJourneyActions } from "./SidebarJourneyActions";
import { SidebarJourneyInput } from "./SidebarJourneyInput";
import { SidebarJourneyNavigation } from "./SidebarJourneyNavigation";
import { Text } from "@twilio-paste/core/text";
import { useStudioContext } from "../../hooks/useStudioContext";

const TITLE = "Race Journey Studio";

export const SidebarWrapper = ({ children, mainContentID, topbarID }: { children: ReactElement | ReactElement[], mainContentID: string, topbarID: string }) => {
	const { sidebar } = useStudioContext();

	return (<>
		{/**
       * No judgement zone
       * To successfully handle a single sidebar that is responsive but also adaptive in it's state handling
       * We need to handle initial render for both mobile and desktop views because the sidebar has inverse initial state
       * - On mobile, the sidebar should start collapsed
       * - On desktop, the sidebar should start expanded
       * We then do a little trickery with opacity and responsive values to stop you seeing the state change flash
       * when it's not mounted we use a transparent sidebar on small screens, after it's mounted we switch to visible,
       * but transition it and delay the transistion start. For desktop, we start visible and never transition.
       */}
		<Box opacity={[!sidebar.mounted ? "0" : "1", "1"]} transitionDelay="50ms" transition="opacity 150ms ease">
			<Sidebar
				element="SIDEBAR_WIDE"
				variant="compact"
				collapsed={sidebar.collapsed}
				mainContentSkipLinkID={mainContentID}
				sidebarNavigationSkipLinkID={sidebar.sidebarNaviationId}
				topbarSkipLinkID={topbarID}
				id={sidebar.sidebarId}
			>
				<SidebarHeader>
					<SidebarHeaderIconButton as="a" href="#">
						<DirectoryIcon size="sizeIcon20" decorative={false} title={TITLE} />
					</SidebarHeaderIconButton>
					<SidebarHeaderLabel>{TITLE}</SidebarHeaderLabel>
				</SidebarHeader>
				<SidebarBody>
					<>
						{!sidebar.collapsed &&
							<Box display="flex" justifyContent="space-between" paddingX="space50">
								<Text as="h2" fontSize="fontSize30" color="colorTextInverse">Journeys</Text>
								<SidebarJourneyActions />
							</Box>}
						{(!sidebar.collapsed && sidebar.isAddingJourney) && <Box paddingX="space50">
							<SidebarJourneyInput />
						</Box>}
						<SidebarJourneyNavigation />

					</>
					{!sidebar.collapsed && <Separator orientation="horizontal" />}
					<>
						{!sidebar.collapsed && <Box display="flex" justifyContent="space-between" paddingX="space50">
							<Text as="h2" fontSize="fontSize30" color="colorTextInverse">Actions</Text>
							<SidebarActionActions />
						</Box>}
						{(!sidebar.collapsed && sidebar.isAddingAction) && <Box paddingLeft="space100" paddingRight="space40">
							<SidebarActionInput />
						</Box>}
						<SidebarActionNavigation />
					</>
				</SidebarBody>
				<SidebarFooter>
					<SidebarCollapseButton
						i18nCollapseLabel="Close sidebar"
						i18nExpandLabel="Open sidebar"
						onClick={() => sidebar.setCollapsed(!sidebar.collapsed)}
					/>
				</SidebarFooter>
			</Sidebar>
		</Box>
		<SidebarPushContentWrapper collapsed={sidebar.collapsed} variant="compact">
			{children}
		</SidebarPushContentWrapper>
	</>);
}