import { ComponentType, ContextType, JourneyInstance, JourneyResource, ViewResource, WriteOnly, generateSid, getFromComponentMap } from "@ciptex-race/journey";
import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import type { DropTargetMonitor } from "react-dnd";
import { FindObjectById } from "../../../functions/FindObjectById";
import { Logger } from "@ciptex-race/logger";
import type { SetSelectedComponent } from "../StateProvider";
import { UpdateViewComponents } from "../../../functions/UpdateViewComponents";

const logger = Logger.getInstance();

interface useJourneyEventsHook {
	journey?: JourneyInstance;
	setSelectedComponent: SetSelectedComponent;
	setJourneyInstance: Dispatch<SetStateAction<JourneyInstance | undefined>>;
	activeView: {
		name?: string;
		value?: Omit<WriteOnly<ViewResource>, "name">;
	};
	setJourneysConfig: Dispatch<SetStateAction<WriteOnly<JourneyResource & { sid: string }>[]>>;
	setIsChanged: Dispatch<SetStateAction<boolean>>;
}

type DropTargetFiredArgs = {
	component: ComponentType & { _id: string },
	contextType: ContextType,
	positionIndex: number,
	item: { friendlyName: string, type: ComponentType["type"] },
	monitor: DropTargetMonitor<any, void>
}


export const useJourneyEvents = ({ journey, setIsChanged, setSelectedComponent, activeView, setJourneyInstance, setJourneysConfig }: useJourneyEventsHook) => {

	const onJourneyUpdated = useCallback((instance: JourneyInstance) => {
		logger.debug("useJourneyEvents", "onJourneyUpdated", instance);
		setJourneyInstance(instance);

		setJourneysConfig((config: WriteOnly<JourneyResource & { sid: string }>[]) => {
			const newArray = [...config];
			const index = newArray.findIndex(obj => obj.sid === instance.sid);

			if (index !== -1) {
				newArray[index] = {
					...newArray[index],
					friendlyName: instance.friendlyName,
					definition: instance.definition
				};
			}

			return newArray;
		});
		setIsChanged(true);
	}, [setIsChanged, setJourneyInstance, setJourneysConfig]);

	const onDropTagetFired = useCallback(async ({ component, contextType, item, positionIndex }: DropTargetFiredArgs) => {
		logger.debug("useJourneyEvents", `onDropTagetFired on Component ${component.type} position ${positionIndex}`, { item });

		if (!activeView?.name || !activeView?.value || !journey) {
			return;
		}

		const { components = [] } = activeView.value || {};
		const { defaultTemplate } = getFromComponentMap(item.type) as any;
		const newComponent = { _id: generateSid("JC"), ...defaultTemplate };

		if (component.type === "view") {
			components.splice(positionIndex, 0, newComponent);
		} else {
			const c = FindObjectById(components, component._id);
			c.children.splice(positionIndex, 0, newComponent);
		}

		await UpdateViewComponents(journey, {
			viewName: activeView.name,
			components
		});

		setSelectedComponent({ component: newComponent, contextType });
	}, [activeView.name, activeView.value, journey, setSelectedComponent]);


	const onOverlayClicked = useCallback(({ component, contextType }: { component: ComponentType & { _id: string }, contextType: ContextType }) => {
		logger.debug("useJourneyEvents", `onOverlayClicked ${component.type}`, { component, contextType });
		if (component.type === "view") {
			setSelectedComponent({ component: { ...component, _id: "view" }, contextType });
		} else {
			setSelectedComponent({ component, contextType });
		}
	}, [setSelectedComponent])

	useEffect(() => {
		if (journey) {
			journey.addListener("OverlayClicked", onOverlayClicked);
			journey.addListener("DropTargetFired", onDropTagetFired);
			journey.addListener("JourneyUpdated", onJourneyUpdated);

			return () => {
				journey.removeListener("OverlayClicked", onOverlayClicked);
				journey.removeListener("DropTargetFired", onDropTagetFired);
				journey.removeListener("JourneyUpdated", onJourneyUpdated);
			}
		}
	}, [journey, onDropTagetFired, onOverlayClicked, onJourneyUpdated]);
}