import { ComponentType, ContextType, ExecutionContextInstance, ExecutionInstance, JourneyInstance, JourneyManager, type JourneyResource, type WriteOnly } from "@ciptex-race/journey";
import { useCallback, useEffect, useState } from "react";
import { CreateExecution } from "../../../functions/CreateExecution";
import { Logger } from "@ciptex-race/logger";

const logger = Logger.getInstance();

export interface useEditorStateHook {
	journeyInstance?: JourneyInstance;
	executionInstance?: ExecutionInstance;
}

interface Props {
	editorsConfigs: WriteOnly<JourneyResource>[],
	selectedComponent?: {
		component?: (ComponentType & { _id: string }),
		contextType?: ContextType
	},
	onContextUpdated: (instance: ExecutionContextInstance) => void;
}

export const useEditor = ({ editorsConfigs, selectedComponent: { component } = {}, onContextUpdated }: Props) => {
	const [journeyInstance, setJourneyInstance] = useState<JourneyInstance | undefined>();
	const [executionInstance, setExecutionInstance] = useState<ExecutionInstance | undefined>();
	const [executionContextInstance, setExecutionContextInstance] = useState<ExecutionContextInstance | undefined>();

	const loadJourney = useCallback(async () => {
		const editorConfig = editorsConfigs.find(x => x.friendlyName === component?.type);
		if (!editorConfig) {
			return;
		}

		const manager = JourneyManager.getInstance();
		let journey: JourneyInstance | undefined;

		if (!journeyInstance || journeyInstance.friendlyName !== editorConfig.friendlyName) {
			journey = await manager.journeys.create(editorConfig);
			setJourneyInstance(journey);
		} else {
			journey = journeyInstance;
		}

		if (journey && executionContextInstance?.contextData._id !== component?._id) {
			const payload: Record<string, any> = {
				viewName: "editor_start",
				contextData: {
					_id: component?._id,
					_type: component?.type,
					...component?.props,
					_state: {

					}
				}
			}

			const p = component?.props as any;

			// Set State Values for Boolean Radio Buttons. Hack for Conditional Statements
			["visible", "required", "disabled"].forEach((item => {
				if (p?.[item]) {
					if (p[item] === undefined || p[item] === "") {
						payload.contextData._state[item] = "";
					}
					else if (p[item] === true || p[item] === false) {
						payload.contextData._state[item] = p[item];
					} else {
						payload.contextData._state[item] = "conditional";
					}
				}
			}));
			const ex = await CreateExecution(journey, payload);
			setExecutionInstance(ex);
		}
	}, [component, editorsConfigs, executionContextInstance?.contextData._id, journeyInstance]);

	const onContextCreated = useCallback(async (instance: ExecutionContextInstance) => {
		setExecutionContextInstance(instance);
	}, []);

	useEffect(() => {
		if (component && component._id) {
			loadJourney();
		}
	}, [component, loadJourney]);

	useEffect(() => {
		if (!executionInstance) {
			return;
		}

		executionInstance.addListener("ContextCreated", onContextCreated);

		executionInstance.executionContext().fetch().then((instance: ExecutionContextInstance) => {
			setExecutionContextInstance(instance);
		}).catch((error: any) => {
			logger.warn("useEditor", "Unable to fetch Execution Instance", error);
		});
		return () => {
			executionInstance?.removeListener("ContextCreated", onContextCreated);
		}
	}, [executionInstance, onContextCreated]);

	useEffect(() => {
		if (!executionContextInstance) {
			return;
		}

		executionContextInstance.addListener("ExecutionContextUpdated", onContextUpdated);

		return () => {
			executionContextInstance?.removeListener("ExecutionContextUpdated", onContextUpdated);
		}
	}, [executionContextInstance, onContextUpdated])

	return {
		journeyInstance,
		executionInstance
	};
}