import { ChangeEvent, FormEvent, KeyboardEvent, useState } from "react";
import { CodeIcon } from "@twilio-paste/icons/esm/CodeIcon";
import { Form } from "@twilio-paste/core/form";
import { Input } from "@twilio-paste/core/input";
import { useConfigContext } from "../../hooks/useConfigContext";
import { useStudioContext } from "../../hooks/useStudioContext";

export const SidebarActionInput = () => {
	const [value, setValue] = useState<string>("");
	const { sidebar } = useStudioContext();
	const { addAction } = useConfigContext();

	const onSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		addAction(value);
		sidebar.setIsAddingAction(false);
	}

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
	}

	const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Escape") {
			sidebar.setIsAddingAction(false);
		}
	}

	const onBlur = () => {
		sidebar.setIsAddingAction(false);
	}

	return (<Form onSubmit={onSubmit}>
		<Input element="SIDEBAR_INPUT" type="text" insertBefore={
			<CodeIcon decorative size="sizeIcon30" />
		} value={value} onChange={onChange} onBlur={onBlur} onKeyDown={onKeyDown} ref={sidebar.newActionInputRef} />
	</Form>);
}