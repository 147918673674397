import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface useGetConfigHook<T> {
	listFunction: (authData?: Record<string, any>) => Promise<T[]>;
	authData?: Record<string, any>;
}

export interface useGetConfigResponse<T> {
	configStatic: T[];
	config: T[];
	isLoaded: boolean;
	add: (payload: T, opts: { navigateTo?: string }) => Promise<void>;
	setConfig: Dispatch<SetStateAction<T[]>>;
}

export const useGetConfig = <T extends Record<string, any>>({ listFunction, authData }: useGetConfigHook<T>): useGetConfigResponse<T> => {
	const [configStatic, setConfigStatic] = useState<T[]>([]);
	const [config, setConfig] = useState<T[]>([]);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const navigate = useNavigate();

	const list = useCallback(async () => {
		setIsLoaded(false);
		const data = await listFunction(authData);
		if (data) {
			setConfigStatic(data);
			setConfig(data);
			setIsLoaded(true);
		}
	}, [listFunction, authData]);

	const add = useCallback(async (payload: T, { navigateTo }: { navigateTo?: string } = {}) => {
		setConfigStatic([...configStatic, payload]);
		setConfig([...config, payload]);

		if (navigateTo) {
			navigate(navigateTo, { replace: true });
		}
	}, [config, configStatic, navigate]);

	useEffect(() => {
		if (!authData || isLoaded) {
			return;
		}

		list();
	}, [list, authData, isLoaded]);

	return {
		add,
		setConfig,
		configStatic,
		config,
		isLoaded
	}
}