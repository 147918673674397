import { ActionInstance, ActionResource } from "@ciptex-race/journey";
import { ChangeEvent, ReactElement, RefObject, createContext, useCallback, useEffect, useRef } from "react";
import { useActionEditor, useActionEditorHook } from "./hooks/useActionEditor";
import { JourneyClient } from "@ciptex/journey-sdk";
import { Logger } from "@ciptex-race/logger";
import { useActionEvents } from "./hooks/useActionEvents";
import { useActionMetaData } from "./hooks/useActionMetadata";
import { useConfigContext } from "../../hooks/useConfigContext";
import { useLoadAction } from "./hooks/useLoadAction";
import { useParams } from "react-router-dom";
import { useStudioContext } from "../../hooks/useStudioContext";

const logger = Logger.getInstance();

export type ActionContextType = {
	actionInstance?: ActionInstance;
	editor: useActionEditorHook;
	saveChanges: () => Promise<void>;
	formRef: RefObject<HTMLDivElement>;
	onFormChange: (e: ChangeEvent<HTMLInputElement>) => void;
	onCodeChange: (value?: string) => void;
	onTemplateChange: (sid: string) => void;
	formData: Omit<ActionResource, "code">;
}

export const ActionContext = createContext<ActionContextType>(null!);

export const ActionProvider = ({ children }: { children: ReactElement }) => {
	const { actionSid } = useParams();
	const { actionsConfig, templatesConfig, authData } = useConfigContext();
	const { actionInstance, setActionInstance } = useLoadAction({
		actionsConfig: actionsConfig.config,
		actionSid
	});
	const editor = useActionEditor();
	const { formData, onFormChange } = useActionMetaData({ actionInstance, editor });
	const { toaster } = useStudioContext();
	const formRef = useRef<HTMLDivElement>(null);

	useActionEvents({
		action: actionInstance,
		setActionInstance,
		setActionsConfig: actionsConfig.setConfig
	});

	const onCodeChange = useCallback((value?: string) => {
		if (value) {
			editor.setIsChanged(true);
			editor.setEditorValue(value);
		}
	}, [editor]);

	const saveChanges = useCallback(async () => {
		try {
			if (!authData || !actionSid) {
				return;
			}

			const client = new JourneyClient({
				accountSid: authData.accountSid,
				token: authData.user.token
			});

			await actionInstance?.update({
				friendlyName: formData.friendlyName,
				fnName: formData.fnName,
				code: editor.editorValue
			});

			await client.action.update({
				friendlyName: formData.friendlyName,
				fnName: formData.fnName,
				code: editor.editorValue
			}, { actionSid });

			toaster.push({
				variant: "success",
				message: "Changes Saved",
				dismissAfter: 2000
			});

			editor.setIsChanged(false);
		} catch (error: any) {
			logger.warn("ActionProvider:saveChanges", "Unable to save changes", error.message);
		}
	}, [actionInstance, actionSid, authData, editor, formData, toaster]);

	const onTemplateChange = useCallback((sid: string) => {
		const template = templatesConfig.config.find(x => x.sid === sid);
		if (!template) {
			return;
		}

		if (editor.isChanged) {
			editor.setPendingAction({ type: "changeCode", payload: template });
			editor.setIsAlertOpen(true);
		} else {
			editor.setEditorValue(template.code);
			editor.setTemplateValue(sid);
		}
	}, [editor, templatesConfig.config]);

	useEffect(() => {
		if (actionInstance) {
			editor.setEditorValue(actionInstance.code);
			const { code } = actionsConfig.config.find(x => x.sid === actionInstance.sid) || {};
			if (!code || actionInstance.code !== code) {
				editor.setIsChanged(true);
			} else {
				editor.setIsChanged(false);
			}
		} else {
			editor.setEditorValue("");
			editor.setIsChanged(false);
		}

	}, [actionInstance, actionsConfig.config]);

	return (<ActionContext.Provider value={{ formData, formRef, actionInstance, editor, saveChanges, onFormChange, onCodeChange, onTemplateChange }}>{children}</ActionContext.Provider>);
}