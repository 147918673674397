import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import { App } from "./App";

const router = createBrowserRouter([
	{
		path: "/studio",
		element: <App />,
		children: [
			{
				index: true,
				async lazy() {
					const { NoSelection } = await import("./pages/NoSelection");
					return { Component: NoSelection };
				}
			},
			{
				path: "action/:actionSid",
				async lazy() {
					const { ActionEditor } = await import("./pages/ActionEditor");
					return { Component: ActionEditor };
				}
			},
			{
				path: "journey/:journeySid",
				async lazy() {
					const { JourneySettings } = await import("./pages/JourneySettings");
					return { Component: JourneySettings };
				}
			},
			{
				path: "journey/:journeySid/:viewName",
				async lazy() {
					const { Designer } = await import("./pages/Designer");
					return { Component: Designer };
				}
			},
			{
				path: "*",
				element: <Navigate to="/studio" replace />
			}
		]
	},
	{
		path: "*",
		element: <Navigate to="/studio" replace />
	}
]);

export const Router = () => {
	return <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />
}