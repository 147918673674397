import { CustomizationProvider, PasteCustomCSS } from "@twilio-paste/customization";
import { ActionProvider } from "./components/ActionProvider/ActionProvider";
import { AppToaster } from "./components/toaster/AppToaster";
import { Box } from "@twilio-paste/core/box";
import { ConfigProvider } from "./components/ConfigProvider/ConfigProvider";
import { Logger } from "@ciptex-race/logger";
import { Outlet } from "react-router-dom";
import { OutletWrapper } from "./components/OutletWrapper";
import { SidebarWrapper } from "./components/sidebar/SidebarWrapper";
import { StateProvider } from "./components/StateProvider/StateProvider";
import { StudioProvider } from "./components/StudioProvider/StudioProvider";
import { StudioTopbar } from "./components/topbar/StudioTopbar";
import { useEffect } from "react";
import { useUID } from "@twilio-paste/core/uid-library";

const logger = Logger.getInstance();

const CUSTOM_ELEMENTS: Record<string, PasteCustomCSS> = {
	"DROP_TARGET": {
		position: "relative",
		_before: {
			borderBottomColor: "colorBorderInverseStrongest"
		}
	},
	"SIDEBAR_INPUT": {
		backgroundColor: "colorBackgroundInverse",
		color: "colorTextInverseWeak",
		boxShadow: "none",
		zIndex: "zIndex40"
	},
	"SIDEBAR_INPUT_ELEMENT": {
		fontWeight: "fontWeightNormal",
		paddingLeft: "space0"
	},
	"SIDEBAR_INPUT_PREFIX": {
		backgroundColor: "colorBackgroundInverse",
		color: "colorTextInverseWeak",
		borderRight: "unset"
	},
	"SIDEBAR_INPUT_VIEW": {
		backgroundColor: "colorBackgroundInverse",
		color: "colorTextInverseWeak",
		boxShadow: "none"
	},
	"SIDEBAR_INPUT_VIEW_ELEMENT": {
		fontWeight: "fontWeightNormal",
		fontSize: "fontSize20"
	},
	"SIDEBAR_INPUT_VIEW_PREFIX": {
		backgroundColor: "colorBackgroundInverse",
		color: "colorTextInverseWeak",
		borderRight: "unset"
	},
	"SIDEBAR_BUTTON": {
		color: "colorTextInverse",
		"&:hover": {
			color: "colorTextInverse",
			backgroundColor: "colorBackgroundInverseStrong",
			boxShadow: "rgb(244, 244, 246) 0px 0px 0px 1px"
		}
	},
	"SIDEBAR_NAVIGATION": {
		overflowY: "auto",
		minHeight: "30%",
		zIndex: "zIndex30",
		paddingY: "space0"
	},
	"SIDEBAR_BODY": {
		display: "flex",
		flexDirection: "column",
		rowGap: "space60",
		paddingTop: "space60"
	},
	"SIDEBAR_WIDE": {
		// width: "17rem !important"
	}
}

export const App = () => {
	const mainContentId = useUID();
	const topbarId = useUID();

	useEffect(() => {
		logger.info("App", "Starting Render");
	}, []);

	return (
		<CustomizationProvider baseTheme="default" elements={CUSTOM_ELEMENTS}>
			<StudioProvider>
				<ConfigProvider>
					<StateProvider>
						<ActionProvider>
							<Box overflow="hidden">
								<SidebarWrapper mainContentID={mainContentId} topbarID={topbarId}>
									<Box display="flex" flexDirection="column" minHeight="100vh" overflow="hidden">
										<AppToaster />
										<StudioTopbar id={topbarId} />
										<OutletWrapper id={mainContentId}>
											<Outlet />
										</OutletWrapper>
									</Box>
								</SidebarWrapper>
							</Box>
						</ActionProvider>
					</StateProvider>
				</ConfigProvider>
			</StudioProvider>
		</CustomizationProvider>);
}