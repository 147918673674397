import { ACTIONS_COMBOBOX, BADGE_AS_TAGS, BADGE_VARIANT_COMBOBOX, BUTTON_SIZE_COMBOBOX, BUTTON_TYPE_COMBOBOX, BUTTON_VARIANT_COMBOBOX, CALLOUT_VARIANTS, CONTENT_TEXT_AREA, DISABLE_RADIOGROUP, DISPLAY_HEADING_VARIANTS, FONT_SIZE_COMBOBOX, FONT_WEIGHT_COMBOBOX, HEADING_AS_TAGS, HEADING_VARIANTS, HREF_INPUT, INPUT_TYPE_COMBOBOX, Journey, NAME_INPUT, NAVIGATE_TO_VIEW_COMBOBOX, RATIO_COMBOBOX, REQUIRED_RADIOGROUP, SRC_INPUT, TEXT_AS_TAGS, TEXT_COLOR_COMBOBOX, VISIBLE_RADIOGROUP } from "../../../editorComponents";
import { JourneyInstance, JourneyManager, JourneyResource, WriteOnly } from "@ciptex-race/journey";
import { useAsyncMemo } from "../../../hooks/useAsyncMemo";
import { useConfigContext } from "../../../hooks/useConfigContext";

export interface useEditorConfigHook {
	journey?: JourneyInstance;
	viewNames: string[];
}

const ON_REMOVE_CLICK_CODE = `const onClick = (e, instance) => {
    window.postMessage({
        source: "journey-studio-editor",
        type: "ComponentRemoveClick", 
        data: instance?.contextData
    }, "${window.location.origin}");
}`

const RADIO_BUTTONS_ONCHANGE = `const onChange = async (e, instance) => {
    const name = e.target.name.split(".")[1];

    try {
        const test = await instance.update({
            contextData: {
                [name]: e.target.value === "conditional" ? "" : e.target.value.toString()
            }
        });
    } catch(error) {
        console.error(error);
    }
}`

export const useEditorConfig = ({ viewNames }: useEditorConfigHook): WriteOnly<JourneyResource>[] => {
	const { actionsConfig } = useConfigContext();

	const actions = useAsyncMemo(async () => {
		const manager = JourneyManager.getInstance();
		const onSubmitForm = await manager.actions.create({
			fnName: "onClick",
			friendlyName: "onRemoveClick",
			code: ON_REMOVE_CLICK_CODE,
			compiledCode: ON_REMOVE_CLICK_CODE
		});

		const onChangeRadioButtons = await manager.actions.create({
			fnName: "onChange",
			friendlyName: "onChangeRadioButtons",
			code: RADIO_BUTTONS_ONCHANGE,
			compiledCode: RADIO_BUTTONS_ONCHANGE
		});

		return {
			onSubmitForm: onSubmitForm.sid,
			onChangeRadioButtons: onChangeRadioButtons.sid
		}
	}, []);

	return [
		Journey("view", [], actions),
		Journey("form-actions", [], actions),
		Journey("form-row", [], actions),
		Journey("description-list", [], actions),
		Journey("ordered-list", [], actions),
		Journey("unordered-list", [], actions),
		Journey("anchor", [
			{
				type: "form-section",
				children: [
					HREF_INPUT,
					CONTENT_TEXT_AREA({ name: "content" })
				]
			}
		], actions),
		Journey("badge", [
			{
				type: "form-section",
				children: [
					CONTENT_TEXT_AREA({ name: "content" }),
					BADGE_VARIANT_COMBOBOX,
					HREF_INPUT,
					{
						type: "combobox",
						props: {
							required: true,
							labelText: "HTML Tag",
							helpText: "HTML Tag to Render",
							items: BADGE_AS_TAGS,
							name: "as"
						}
					}
				]
			}
		], actions),
		Journey("tab", [
			{
				type: "form-section",
				children: [
					...DISABLE_RADIOGROUP(actions)
				]
			}
		], actions),
		Journey("tabs", [
			{
				type: "form-section",
				children: [
					...VISIBLE_RADIOGROUP(actions)
				]
			}

		], actions),
		Journey("iframe", [
			{
				type: "form-section",
				children: [
					SRC_INPUT,
					...VISIBLE_RADIOGROUP(actions)
				]
			}
		], actions),
		Journey("image", [
			{
				type: "form-section",
				children: [
					SRC_INPUT,
					RATIO_COMBOBOX
				]
			}
		], actions),
		Journey("display-heading", [
			{
				type: "callout",
				props: {
					variant: "neutral",
					content: {
						heading: "About Display Heading",
						text: "Display Heading should not be used interchangeably with the Heading component. While Heading is for adding multiple levels of hierarchy and structuring the content of any page."
					}
				}
			},
			{
				type: "form-section",
				children: [
					CONTENT_TEXT_AREA({ name: "content" }),
					{
						type: "combobox",
						props: {
							required: true,
							labelText: "Heading Size",
							items: DISPLAY_HEADING_VARIANTS,
							name: "variant"
						}
					},
					{
						type: "combobox",
						props: {
							required: true,
							labelText: "Heading Type",
							items: HEADING_AS_TAGS,
							name: "as"
						}
					},
					{
						type: "callout",
						props: {
							variant: "neutral",
							content: {
								text: "Predictable heading order helps your users better understand your content."
							}
						}
					}
				]
			}
		], actions),
		Journey("heading", [
			{
				type: "form-section",
				children: [
					CONTENT_TEXT_AREA({ name: "content" }),
					{
						type: "combobox",
						props: {
							required: true,
							labelText: "Heading Size",
							items: HEADING_VARIANTS,
							name: "variant"
						}
					},
					{
						type: "combobox",
						props: {
							required: true,
							labelText: "Heading Type",
							items: HEADING_AS_TAGS,
							name: "as"
						}
					},
					{
						type: "callout",
						props: {
							variant: "neutral",
							content: {
								text: "Predictable heading order helps your users better understand your content."
							}
						}
					}
				]
			}
		], actions),
		Journey("text", [
			{
				type: "form-section",
				children: [
					CONTENT_TEXT_AREA({ name: "content" }),
					FONT_SIZE_COMBOBOX,
					FONT_WEIGHT_COMBOBOX,
					TEXT_COLOR_COMBOBOX,
					{
						type: "combobox",
						props: {
							required: true,
							labelText: "Text Type",
							items: TEXT_AS_TAGS,
							name: "as"
						}
					}
				]
			}
		], actions),
		Journey("paragraph", [
			{
				type: "form-section",
				children: [
					CONTENT_TEXT_AREA({ name: "content" })
				]
			}
		], actions),
		Journey("form", [
			{
				type: "form-section",
				children: [
					NAVIGATE_TO_VIEW_COMBOBOX(viewNames),
					ACTIONS_COMBOBOX({ name: "onSubmit", labelText: "On Submit Action" }, actionsConfig.config),
					ACTIONS_COMBOBOX({ name: "onReset", labelText: "On Reset Action" }, actionsConfig.config),
					...VISIBLE_RADIOGROUP(actions)
				]
			}
		], actions),
		Journey("form-section", [
			{
				type: "form-section",
				children: [
					CONTENT_TEXT_AREA({ name: "labelText", labelText: "Label", required: false }),
					CONTENT_TEXT_AREA({ name: "helpText", labelText: "Help Text", required: false }),
					...VISIBLE_RADIOGROUP(actions)
				]
			}
		], actions),
		Journey("callout", [
			{
				type: "form-section",
				children: [
					CONTENT_TEXT_AREA({ name: "content.heading", labelText: "Heading", required: true }),
					CONTENT_TEXT_AREA({ name: "content.text", labelText: "Text", required: false }),
					{
						type: "combobox",
						props: {
							required: true,
							labelText: "Variant",
							helpText: "Callout Variant",
							items: CALLOUT_VARIANTS,
							name: "variant"
						}
					},
					...VISIBLE_RADIOGROUP(actions)
				]
			}
		], actions),
		Journey("switch", [
			{
				type: "form-section",
				children: [
					NAME_INPUT,
					CONTENT_TEXT_AREA({ name: "labelText", labelText: "Label", required: true }),
					CONTENT_TEXT_AREA({ name: "helpText", labelText: "Help Text", required: false }),
					ACTIONS_COMBOBOX({ name: "onChange", labelText: "On Change Action" }, actionsConfig.config),
					...VISIBLE_RADIOGROUP(actions),
					...DISABLE_RADIOGROUP(actions)
				]
			}
		], actions),
		Journey("text-area", [
			{
				type: "form-section",
				children: [
					NAME_INPUT,
					CONTENT_TEXT_AREA({ name: "labelText", labelText: "Label", required: true }),
					CONTENT_TEXT_AREA({ name: "helpText", labelText: "Help Text", required: false }),
					// ACTIONS_COMBOBOX({ name: "onLoad", labelText: "On Load Action" }, actionsConfig.config),
					ACTIONS_COMBOBOX({ name: "onChange", labelText: "On Change Action" }, actionsConfig.config),
					...REQUIRED_RADIOGROUP(actions),
					...DISABLE_RADIOGROUP(actions),
					...VISIBLE_RADIOGROUP(actions)
				]
			}
		], actions),
		Journey("combobox", [
			{
				type: "form-section",
				children: [
					NAME_INPUT,
					CONTENT_TEXT_AREA({ name: "labelText", labelText: "Label", required: true }),
					CONTENT_TEXT_AREA({ name: "helpText", labelText: "Help Text", required: false }),
					ACTIONS_COMBOBOX({ name: "items", labelText: "Items" }, actionsConfig.config),
					{
						type: "input",
						props: {
							name: "optionTemplate",
							type: "text" as any,
							labelText: "Option Template Key",
							helpText: "The Object Key to use as the Object Template"
						}
					},
					{
						type: "input",
						props: {
							name: "itemToString",
							type: "text" as any,
							labelText: "Item To String Key",
							helpText: "The Object Key to use as the Item to String Property"
						}
					},
					ACTIONS_COMBOBOX({ name: "onChange", labelText: "On Change Action" }, actionsConfig.config),
					...REQUIRED_RADIOGROUP(actions),
					...DISABLE_RADIOGROUP(actions),
					...VISIBLE_RADIOGROUP(actions)
				]
			}
		], actions),
		Journey("input", [
			{
				type: "form-section",
				children: [
					NAME_INPUT,
					INPUT_TYPE_COMBOBOX,
					{
						type: "input",
						props: {
							type: "text",
							name: "placeholder",
							labelText: "Placeholder"
						}
					},
					{
						type: "input",
						props: {
							type: "text",
							name: "pattern",
							labelText: "Validation Pattern",
							helpText: "The pattern attribute specifies a regular expression the form control's value should match"
						}
					},
					CONTENT_TEXT_AREA({ name: "labelText", labelText: "Label", required: true }),
					CONTENT_TEXT_AREA({ name: "helpText", labelText: "Help Text", required: false }),
					ACTIONS_COMBOBOX({ name: "onChange", labelText: "On Change Action" }, actionsConfig.config),
					ACTIONS_COMBOBOX({ name: "onBlur", labelText: "On Blur Action" }, actionsConfig.config),
					...REQUIRED_RADIOGROUP(actions),
					...DISABLE_RADIOGROUP(actions),
					...VISIBLE_RADIOGROUP(actions)
				]
			}

		], actions),
		Journey("radio-group", [
			{
				type: "form-section",
				children: [
					NAME_INPUT,
					CONTENT_TEXT_AREA({ name: "labelText", labelText: "Label", required: true }),
					CONTENT_TEXT_AREA({ name: "helpText", labelText: "Help Text", required: false }),
					ACTIONS_COMBOBOX({ name: "onChange", labelText: "On Change Action" }, actionsConfig.config),
					...REQUIRED_RADIOGROUP(actions),
					...DISABLE_RADIOGROUP(actions),
					...VISIBLE_RADIOGROUP(actions)
				]
			}
		], actions),
		Journey("checkbox", [
			{
				type: "form-section",
				children: [
					NAME_INPUT,
					CONTENT_TEXT_AREA({ name: "labelText", labelText: "Label", required: true }),
					CONTENT_TEXT_AREA({ name: "helpText", labelText: "Help Text", required: false }),
					ACTIONS_COMBOBOX({ name: "onChange", labelText: "On Change Action" }, actionsConfig.config),
					...REQUIRED_RADIOGROUP(actions),
					...DISABLE_RADIOGROUP(actions),
					...VISIBLE_RADIOGROUP(actions)
				]
			}

		], actions),
		Journey("date-picker", [
			{
				type: "form-section",
				children: [
					NAME_INPUT,
					CONTENT_TEXT_AREA({ name: "labelText", labelText: "Label", required: true }),
					CONTENT_TEXT_AREA({ name: "helpText", labelText: "Help Text", required: false }),
					ACTIONS_COMBOBOX({ name: "onChange", labelText: "On Change Action" }, actionsConfig.config),
					...REQUIRED_RADIOGROUP(actions),
					...DISABLE_RADIOGROUP(actions),
					...VISIBLE_RADIOGROUP(actions)
				]
			}

		], actions),
		Journey("time-picker", [
			{
				type: "form-section",
				children: [
					NAME_INPUT,
					CONTENT_TEXT_AREA({ name: "labelText", labelText: "Label", required: true }),
					CONTENT_TEXT_AREA({ name: "helpText", labelText: "Help Text", required: false }),
					ACTIONS_COMBOBOX({ name: "onChange", labelText: "On Change Action" }, actionsConfig.config),
					...REQUIRED_RADIOGROUP(actions),
					...DISABLE_RADIOGROUP(actions),
					...VISIBLE_RADIOGROUP(actions)
				]
			}
		], actions),
		Journey("button", [
			{
				type: "form-section",
				children: [
					CONTENT_TEXT_AREA({ name: "content", labelText: "Label", required: true }),
					BUTTON_VARIANT_COMBOBOX,
					BUTTON_TYPE_COMBOBOX,
					BUTTON_SIZE_COMBOBOX,
					ACTIONS_COMBOBOX({
						name: "onClick",
						labelText: "On Click Action",
						disabled: "{{xif 'contextData.type === \"submit\" || contextData.type === \"reset\"'}}"
					}, actionsConfig.config),
					NAVIGATE_TO_VIEW_COMBOBOX(viewNames, {
						disabled: "{{xif 'contextData.type === \"submit\" || contextData.type === \"reset\"'}}"
					}),
					...DISABLE_RADIOGROUP(actions),
					...VISIBLE_RADIOGROUP(actions)
				]
			}
		], actions)
	];
}