import { Badge } from "@twilio-paste/core/badge";
import { Button } from "@twilio-paste/core/button";
import { RaiseHandIcon } from "@twilio-paste/icons/esm/RaiseHandIcon";
import { useActionContext } from "hooks/useActionContext";
import { useCallback } from "react";

export const TopbarActionSave = () => {
	const { saveChanges, editor } = useActionContext();

	const onClick = useCallback(() => {
		saveChanges();
	}, [saveChanges]);

	return (<>
		{editor.isChanged && <Badge as="span" variant="decorative20">
			<RaiseHandIcon decorative />
			Unsaved Changes
		</Badge>}
		<Button disabled={!editor.isChanged} variant="primary" onClick={onClick}>Save Changes</Button>
	</>);
}