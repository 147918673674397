import { GetViewByName } from "./GetViewByName";
import { JourneyInstance } from "@ciptex-race/journey";
import { Logger } from "@ciptex-race/logger";

const logger = Logger.getInstance();

export const CreateExecution = async (journey: JourneyInstance, { viewName, contextData }: { viewName?: string, contextData?: Record<string, any> } = {}) => {
	try {
		logger.debug("CreateExecution", journey.sid, contextData);
		let view
		if (viewName) {
			view = await GetViewByName(journey, { viewName });
		}

		const execution = await journey.executions.create({
			taskSid: "WT123",
			reservationSid: "WR456",
			activeViewSid: view?.sid,
			contextData: contextData || {}
		});

		return execution;
	} catch (error: any) {
		logger.error("CreateExecution", error);
	}
}