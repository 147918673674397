import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { ExecutionInstance, JourneyInstance, JourneyResource, WriteOnly } from "@ciptex-race/journey";
import { CreateExecution } from "../../../functions/CreateExecution";
import { GetJourney } from "../../../functions/GetJourney";
import { Logger } from "@ciptex-race/logger";
import { useNavigate } from "react-router-dom";

const logger = Logger.getInstance();

interface useLoadJourneyHook {
	isConfigLoaded: boolean;
	journeysConfig?: WriteOnly<JourneyResource & { sid: string }>[];
	journeySid?: string;
}

interface useLoadJourneyHookResponse {
	journeyInstance?: JourneyInstance,
	executionInstance?: ExecutionInstance,
	setJourneyInstance: Dispatch<SetStateAction<JourneyInstance | undefined>>;
	setExecutionInstance: Dispatch<SetStateAction<ExecutionInstance | undefined>>;
}

export const useLoadJourney = ({ journeysConfig, journeySid, isConfigLoaded }: useLoadJourneyHook): useLoadJourneyHookResponse => {
	const [isCreating, setIsCreating] = useState<boolean>(false);
	const [journeyInstance, setJourneyInstance] = useState<JourneyInstance>();
	const [executionInstance, setExecutionInstance] = useState<ExecutionInstance>();
	const navigate = useNavigate();

	const load = useCallback(async () => {
		logger.debug("useLoadJourney", `loading journey ${journeySid}`);

		if (!journeySid || !journeysConfig) {
			setIsCreating(false);
			navigate("/studio", { replace: true });
			return;
		}

		setIsCreating(true);
		const journeyConfig = journeysConfig.find(x => x.sid === journeySid);
		if (!journeyConfig) {
			setIsCreating(false);
			navigate("/studio", { replace: true });
			return;
		}

		const journey = await GetJourney(journeyConfig);
		if (!journey) {
			setIsCreating(false);
			navigate("/studio", { replace: true });
			return;
		}

		journey.setOptions({ overlay: true });
		const execution = await CreateExecution(journey);

		setJourneyInstance(journey);
		setExecutionInstance(execution);
		setIsCreating(false);
	}, [journeySid, journeysConfig, navigate]);

	useEffect(() => {
		if (isCreating) {
			logger.warn("useLoadJourney", "rejected due to isCreating");
			return;
		}

		if (isConfigLoaded) {
			load();
		}
	}, [isConfigLoaded, isCreating, load]);

	return {
		journeyInstance,
		executionInstance,
		setJourneyInstance,
		setExecutionInstance
	}
}