import { ChangeEvent, FormEvent, KeyboardEvent, useState } from "react";
import { MediaBody, MediaFigure, MediaObject } from "@twilio-paste/core/media-object"
import { ChevronDisclosureIcon } from "@twilio-paste/icons/esm/ChevronDisclosureIcon";
import { DirectoryIcon } from "@twilio-paste/icons/esm/DirectoryIcon";
import { Form } from "@twilio-paste/core/form";
import { Input } from "@twilio-paste/core/input";
import { useConfigContext } from "../../hooks/useConfigContext";
import { useStudioContext } from "../../hooks/useStudioContext";

export const SidebarJourneyInput = () => {
	const [value, setValue] = useState<string>("");
	const { sidebar } = useStudioContext();
	const { addJourney } = useConfigContext();

	const onSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		addJourney(value);
		sidebar.setIsAddingJourney(false);
	}

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
	}

	const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Escape") {
			sidebar.setIsAddingJourney(false);
		}
	}

	const onBlur = () => {
		sidebar.setIsAddingJourney(false);
	}

	return (<Form onSubmit={onSubmit}>
		<MediaObject verticalAlign="center">
			<MediaFigure>
				<ChevronDisclosureIcon decorative color="colorTextInverseWeaker" />
			</MediaFigure>
			<MediaBody>
				<Input element="SIDEBAR_INPUT" type="text" insertBefore={
					<DirectoryIcon decorative size="sizeIcon30" />
				} value={value} onChange={onChange} onBlur={onBlur} onKeyDown={onKeyDown} ref={sidebar.newJourneyInputRef} />
			</MediaBody>
		</MediaObject>
	</Form>
	)
}