import { ActionInstance, ActionResource, WriteOnly } from "@ciptex-race/journey";
import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { Logger } from "@ciptex-race/logger";

interface useActionEventsHook {
	action?: ActionInstance;
	setActionInstance: Dispatch<SetStateAction<ActionInstance | undefined>>;
	setActionsConfig: Dispatch<SetStateAction<WriteOnly<ActionResource & { sid: string; }>[]>>;
}

const logger = Logger.getInstance();

export const useActionEvents = ({ action, setActionsConfig, setActionInstance }: useActionEventsHook) => {
	const onActionUpdated = useCallback((instance: ActionInstance) => {
		logger.debug("useActionEvents", "onActionUpdated", instance);
		setActionInstance(instance);

		setActionsConfig((config: WriteOnly<ActionResource & { sid: string }>[]) => {
			const newArray = [...config];
			const index = newArray.findIndex(obj => obj.sid === instance.sid);

			if (index !== -1) {
				newArray[index] = {
					...newArray[index],
					friendlyName: instance.friendlyName,
					fnName: instance.fnName,
					code: instance.code
				};
			}

			return newArray;
		});
	}, [setActionInstance, setActionsConfig]);

	useEffect(() => {
		if (action) {
			action.addListener("ActionUpdated", onActionUpdated);

			return () => {
				action.removeListener("ActionUpdated", onActionUpdated);
			}
		}
	}, [action, onActionUpdated]);
}