import { useCallback, useState } from "react"

type DesignerView = "preview" | "code" | "split";

export interface useDesignerViewHook {
	view: DesignerView;
	change: (newView: DesignerView) => void;
}

export const useDesignerView = () => {
	const [view, setView] = useState<DesignerView>("preview");

	const change = useCallback((newView: DesignerView): void => {
		setView(newView);
	}, []);

	return {
		view,
		change
	}
}