import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { ExecutionInstance } from "@ciptex-race/journey";
import { Logger } from "@ciptex-race/logger";
import { SetSelectedComponent } from "../StateProvider";
import { useNavigate } from "react-router-dom";

interface useExecutionEventsHook {
	execution?: ExecutionInstance;
	journeySid?: string;
	setSelectedComponent: SetSelectedComponent;
	setExecutionInstance: Dispatch<SetStateAction<ExecutionInstance | undefined>>;
}

const logger = Logger.getInstance();

export const useExecutionEvents = ({ execution, journeySid, setSelectedComponent, setExecutionInstance }: useExecutionEventsHook) => {
	const navigate = useNavigate();

	const onExecutionUpdated = useCallback((instance: ExecutionInstance) => {
		logger.debug("useExecutionEvents", "onExecutionUpdated", instance);
		setExecutionInstance(instance);
	}, [setExecutionInstance]);

	const onActiveViewChange = useCallback((executionInstance: ExecutionInstance) => {
		logger.debug("useExecutionEvents", "onActiveViewChange", executionInstance.sid);
		navigate(`journey/${journeySid}/${executionInstance.activeViewUniqueName}`, { replace: true });
		setSelectedComponent({ component: { type: "view", _id: "view" }, contextType: "view" } as any);
	}, [journeySid, navigate, setSelectedComponent]);

	useEffect(() => {
		if (execution) {
			execution.addListener("ActiveViewChanged", onActiveViewChange);
			execution.addListener("ExecutionUpdated", onExecutionUpdated);

			return () => {
				execution.removeListener("ActiveViewChanged", onActiveViewChange);
				execution.removeListener("ExecutionUpdated", onExecutionUpdated);
			}
		}
	}, [execution, onActiveViewChange, onExecutionUpdated]);
}