import { Logger } from "@ciptex-race/logger";
import ReactDOM from "react-dom/client";
import { Router } from "./Router";


const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

const getPreferredScheme = () => window?.matchMedia?.("(prefers-color-scheme:dark)")?.matches ? "dark" : "light";
Logger.setOptions({
	appPrefix: ["RaceJourney", "STUDIO"],
	appPrefixColor: getPreferredScheme() === "light" ? "green" : "pink"
});

root.render(<Router />);