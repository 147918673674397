import { Button } from "@twilio-paste/core/button";
import { ButtonGroup } from "@twilio-paste/core/button-group";
import { CodeIcon } from "@twilio-paste/icons/esm/CodeIcon";
import { Tooltip } from "@twilio-paste/core/tooltip";
import { useActionContext } from "hooks/useActionContext";
import { useStudioContext } from "../../hooks/useStudioContext";

export const SidebarActionActions = () => {
	const { sidebar } = useStudioContext();
	const { editor } = useActionContext();

	const onClickNewAction = () => {
		sidebar.setIsAddingView(false);
		sidebar.setIsAddingJourney(false);
		sidebar.setIsAddingAction(true);
		setTimeout(() => {
			sidebar.newActionInputRef.current?.focus();
		}, 50);
	}

	return (<ButtonGroup>
		<Tooltip text="New Action..." placement="bottom-end">
			<Button variant="inverse" size="reset" onClick={onClickNewAction} disabled={editor.isChanged}>
				<CodeIcon decorative={false} title="New Action" />
			</Button>
		</Tooltip>
		<></>
	</ButtonGroup>);
}