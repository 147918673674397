import { ActionResource, ICombobox, IForm, IInput, IRadioButtonGroup, ITextArea, JourneyResource, WriteOnly } from "@ciptex-race/journey";
import { fontSizes, fontWeights, textColors } from "@twilio-paste/design-tokens/dist/tokens.es6.js";

export type CallbackType = (event: string, payload?: Record<string, any>) => void;

export const BADGE_AS_TAGS = ["span", "button", "a"];
export const TEXT_AS_TAGS = ["div", "span"];
export const HEADING_AS_TAGS = ["h1", "h2", "h3", "h4", "h5", "h6", "div", "span", "header"];
export const DISPLAY_HEADING_VARIANTS = ["displayHeading10", "displayHeading20", "displayHeading30"];
export const HEADING_VARIANTS = ["heading10", "heading20", "heading30", "heading40", "heading50", "heading60"];
export const CALLOUT_VARIANTS = ["neutral", "warning", "error", "success", "new"];
export const INPUT_TYPES = ["number", "hidden", "text", "email", "password", "search", "tel"];
export const BUTTON_TYPES = ["submit", "button", "reset"];
export const BUTTON_VARIANTS = ["primary", "primary_icon", "secondary", "secondary_icon", "destructive", "destructive_icon", "destructive_link", "destructive_secondary", "link", "inverse_link", "inverse", "reset"];
export const BUTTON_SIZES = ["small", "default", "icon", "icon_small", "reset", "rounded_small", "circle", "circle_small"];
export const BADGE_VARIANTS = ["neutral", "warning", "error", "success", "new", "decorative10", "decorative20", "decorative30", "decorative40", "neutral_counter", "error_counter", "default", "info"];

export const NAVIGATE_TO_VIEW_COMBOBOX = (viewNames: string[], { disabled }: { disabled?: string | boolean } = {}): ICombobox => {
	return {
		type: "combobox",
		props: {
			labelText: "Navigate to View",
			items: viewNames,
			name: "navigateToView",
			disabled
		}
	}
}

export const ACTIONS_COMBOBOX = ({ name, labelText, disabled }: { name: string, labelText: string, disabled?: string | boolean }, actionsConfig: WriteOnly<ActionResource & { sid: string }>[]): ICombobox => {
	return {
		type: "combobox",
		props: {
			labelText,
			itemToString: "friendlyName",
			optionTemplate: "friendlyName",
			itemToValue: "sid",
			items: actionsConfig,
			disabled,
			name
		}
	}
}

export const CONTENT_TEXT_AREA = ({ name, labelText = "Content", required = true, disabled, visible }: { name: string, labelText?: string, required?: boolean, disabled?: boolean | string, visible?: boolean | string }): ITextArea => {
	return {
		type: "text-area",
		props: {
			name,
			required,
			disabled,
			visible,
			labelText,
			helpText: "You can use Handlebars to pull data from Context"
		}
	}
};

export const BOOLEAN_HANDLEBARS_RADIOGROUP = ({ name, labelText, helpText, labels }: { name: string, labelText: string, helpText?: string, labels: [string, string, string] }, actions?: Record<string, any>): [IRadioButtonGroup, ITextArea] => {
	return [
		{
			type: "radio-button-group",
			props: {
				labelText: labelText,
				orientation: "horizontal",
				name: `_state.${name}`,
				onChange: actions?.onChangeRadioButtons,
				helpText: helpText,
				items: [
					{ labelText: labels[0], value: true },
					{ labelText: labels[1], value: false },
					{ labelText: labels[2], value: "conditional" }
				]
			}
		},
		CONTENT_TEXT_AREA({
			name: name,
			labelText: "Conditional Statement",
			required: true,
			visible: `{{xif 'contextData._state?.["${name}"] === "conditional"' }}`
		})
	]
}

export const VISIBLE_RADIOGROUP = (actions?: Record<string, string>) => {
	return [
		...BOOLEAN_HANDLEBARS_RADIOGROUP({
			name: "visible", labelText: "Visible", labels: [
				"{{icon 'ShowIcon'}}",
				"{{icon 'HideIcon'}}",
				"{{icon 'CodeIcon'}}"
			]
		}, actions)
	]
}

export const DISABLE_RADIOGROUP = (actions?: Record<string, string>) => {
	return [
		...BOOLEAN_HANDLEBARS_RADIOGROUP({
			name: "disabled", labelText: "Disabled", labels: [
				"{{icon 'AcceptIcon'}}",
				"{{icon 'CheckboxLineIcon'}}",
				"{{icon 'CodeIcon'}}"
			]
		}, actions)
	]
};

export const REQUIRED_RADIOGROUP = (actions?: Record<string, string>) => {
	return [
		...BOOLEAN_HANDLEBARS_RADIOGROUP({
			name: "required", labelText: "Required", labels: [
				"{{icon 'AcceptIcon'}}",
				"{{icon 'CheckboxLineIcon'}}",
				"{{icon 'CodeIcon'}}"
			]
		}, actions)
	]
};

export const NAME_INPUT: IInput = {
	type: "input",
	props: {
		name: "name",
		type: "text" as any,
		required: true,
		labelText: "Name",
		helpText: "Form Control Name"
	}
}

export const INPUT_TYPE_COMBOBOX: ICombobox = {
	type: "combobox",
	props: {
		labelText: "Type",
		items: INPUT_TYPES,
		name: "type"
	}
};

export const BUTTON_TYPE_COMBOBOX: ICombobox = {
	type: "combobox",
	props: {
		labelText: "Type",
		items: BUTTON_TYPES,
		name: "type"
	}
};

export const BUTTON_SIZE_COMBOBOX: ICombobox = {
	type: "combobox",
	props: {
		labelText: "Size",
		items: BUTTON_SIZES,
		name: "size"
	}
};

export const BUTTON_VARIANT_COMBOBOX: ICombobox = {
	type: "combobox",
	props: {
		required: true,
		labelText: "Variant",
		items: BUTTON_VARIANTS,
		name: "variant"
	}
};

export const BADGE_VARIANT_COMBOBOX: ICombobox = {
	type: "combobox",
	props: {
		required: true,
		labelText: "Variant",
		items: BADGE_VARIANTS,
		name: "variant"
	}
};

export const FONT_SIZE_COMBOBOX: ICombobox = {
	type: "combobox",
	props: {
		labelText: "Font Size",
		items: Object.keys(fontSizes),
		name: "fontSize"
	}
};

export const FONT_WEIGHT_COMBOBOX: ICombobox = {
	type: "combobox",
	props: {
		labelText: "Font Weight",
		items: Object.keys(fontWeights),
		name: "fontWeight"
	}
}

export const TEXT_COLOR_COMBOBOX: ICombobox = {
	type: "combobox",
	props: {
		labelText: "Text Colors",
		items: Object.keys(textColors),
		name: "color"
	}
}

export const RATIO_COMBOBOX: ICombobox = {
	type: "combobox",
	props: {
		labelText: "Ratio",
		items: ["16:9", "4:3"],
		name: "ratio"
	}
}

export const HREF_INPUT: IInput = {
	type: "input",
	props: {
		labelText: "Link URL",
		type: "url" as any,
		helpText: "Link URL. You can use Handlebars to pull data from Context",
		name: "href"
	}
}

export const SRC_INPUT: IInput = {
	type: "input",
	props: {
		labelText: "Source URL",
		type: "text",
		helpText: "Source URL. You can use Handlebars to pull data from Context",
		name: "src"
	}
}

export const Form = (children: IForm["children"], friendlyName: string, actions?: Record<string, string>): IForm => {
	return {
		type: "form",
		children: [
			...children,
			{
				type: "form-actions",
				children: [
					{
						type: "button",
						props: {
							variant: "primary",
							content: "Save",
							type: "submit"
						}
					},
					{
						type: "button",
						props: {
							onClick: actions?.onSubmitForm,
							variant: "destructive",
							content: "Remove",
							type: "button",
							disabled: friendlyName === "view"
						}
					}
				]
			}
		]
	}
};

export const Journey = (friendlyName: string, children: IForm["children"], actions?: Record<string, string>): WriteOnly<JourneyResource> => {
	return {
		friendlyName: friendlyName,
		definition: {
			views: {
				"editor_start": {
					components: [
						// {
						//     type: "heading",
						//     props: {
						//         content: "{{contextData._id}}",
						//         variant: "heading50",
						//         as: "h3"
						//     }
						// },
						Form(children, friendlyName, actions)
					]
				}
			}
		}
	}
}