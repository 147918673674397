import { RadioButton, RadioButtonGroup } from "@twilio-paste/core/radio-button-group";
import { CodeIcon } from "@twilio-paste/icons/esm/CodeIcon";
import { ColumnIcon } from "@twilio-paste/icons/esm/ColumnIcon";
import { WebCapableIcon } from "@twilio-paste/icons/esm/WebCapableIcon";
import { useParams } from "react-router-dom";
import { useStateContext } from "../../hooks/useStateContext";
import { useStudioContext } from "../../hooks/useStudioContext";

export const TopbarViewToggle = () => {
	const { viewName } = useParams();
	const { journeyInstance } = useStateContext();
	const { designerView } = useStudioContext();

	const onChange = (value: string) => {
		designerView.change(value as any);
	}

	return (
		<RadioButtonGroup disabled={!journeyInstance || !viewName} attached name="designer-view" onChange={onChange} legend="" value={designerView.view}>
			<RadioButton value="preview">
				<WebCapableIcon decorative={false} title="preview" />
			</RadioButton>
			<RadioButton value="split">
				<ColumnIcon decorative={false} title="split" />
			</RadioButton>
			<RadioButton value="code">
				<CodeIcon decorative={false} title="code" />
			</RadioButton>
		</RadioButtonGroup>);
}