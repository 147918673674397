import { ActionInstance, ActionResource, JourneyManager, WriteOnly } from "@ciptex-race/journey";
import { Logger } from "@ciptex-race/logger";

const logger = Logger.getInstance();

export const GetAction = async (config: WriteOnly<ActionResource & { sid: string }>): Promise<ActionInstance> => {
	const manager = JourneyManager.getInstance();
	try {
		logger.debug("useAction", `getAction Attempting to retrieve action for ${config.sid}`);
		const action = await manager.actions(config.sid).fetch();
		return action;
	} catch (error) {
		logger.debug("useAction", `getAction Action not found. Creating action for ${config.sid}`);
		const action = await manager.actions.create(config);
		return action;
	}
}