import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

export type PendingAction = { type: "navigate" | "changeCode", payload: any }

export interface useActionEditorHook {
	isChanged: boolean;
	setIsChanged: Dispatch<SetStateAction<boolean>>;
	isAlertOpen: boolean;
	setIsAlertOpen: Dispatch<SetStateAction<boolean>>;
	editorValue: string;
	setEditorValue: Dispatch<SetStateAction<string>>;
	pendingAction: PendingAction | null;
	setPendingAction: Dispatch<SetStateAction<PendingAction | null>>;
	onDialogConfirm: () => Promise<void>;
	templateValue: string;
	setTemplateValue: Dispatch<SetStateAction<string>>;
}

export const useActionEditor = (): useActionEditorHook => {
	const [isChanged, setIsChanged] = useState<boolean>(false);
	const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
	const [editorValue, setEditorValue] = useState<string>("");
	const [pendingAction, setPendingAction] = useState<PendingAction | null>(null);
	const [templateValue, setTemplateValue] = useState<string>("");
	const navigate = useNavigate();

	const onDialogConfirm = useCallback(async () => {
		if (pendingAction?.type === "navigate") {
			navigate(pendingAction.payload, { replace: true });
		} else if (pendingAction?.type === "changeCode") {
			setEditorValue(pendingAction.payload.code);
			setTemplateValue(pendingAction.payload.sid)
		}

		setPendingAction(null);
		setIsChanged(false);
		setIsAlertOpen(false);
	}, [navigate, pendingAction]);

	return {
		isChanged,
		setIsChanged,
		isAlertOpen,
		setIsAlertOpen,
		editorValue,
		setEditorValue,
		pendingAction,
		setPendingAction,
		templateValue,
		setTemplateValue,
		onDialogConfirm
	}
}