type ComponentsGenericType = {
	_id: string;
	type: string;
	children?: Array<ComponentsGenericType>
};

export const FindObjectParentById = (components: ComponentsGenericType[], id: string) => {
	for (const component of components) {
		if (component._id === id) {
			return components;
		} else {
			if (component.children && component.children.length > 0) {
				const result: any = FindObjectParentById(component.children, id);
				if (result) {
					return result;
				}
			}
		}
	}
}