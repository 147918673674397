import { Menu, MenuButton, MenuItem, useMenuState } from "@twilio-paste/core/menu";
import { Topbar, TopbarActions } from "@twilio-paste/core/topbar";
import { Box } from "@twilio-paste/core/box";
import { SupportIcon } from "@twilio-paste/icons/esm/SupportIcon";
import { TopbarActionSave } from "./TopbarActionSave";
import { TopbarJourneySave } from "./TopbarJourneySave";
import { TopbarViewToggle } from "./TopbarViewToggle";
import { useParams } from "react-router-dom";
// import { Text } from "@twilio-paste/core/text";

export const StudioTopbar = ({ id }: { id: string }) => {
	const supportMenu = useMenuState();
	const { journeySid, actionSid } = useParams();

	return (<Box minWidth="size70">
		<Topbar id={id}>
			{/* <Text as="span" fontSize="fontSize10">{window.location.toString()}</Text> */}
			<TopbarActions justify="end">
				{journeySid && <>
					<TopbarJourneySave />
					<TopbarViewToggle />
				</>}
				{actionSid && <TopbarActionSave />}
				<MenuButton {...supportMenu} variant="secondary_icon" size="reset">
					<SupportIcon decorative={false} title="Support" />
				</MenuButton>
				<Menu {...supportMenu} aria-label="Support">
					<MenuItem {...supportMenu}>Support Center</MenuItem>
					<MenuItem {...supportMenu}> Developer Docs</MenuItem>
				</Menu>
			</TopbarActions>
		</Topbar>
	</Box>)
}