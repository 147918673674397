import { Dispatch, RefObject, SetStateAction, useEffect, useRef, useState } from "react";
import { useUID } from "@twilio-paste/core/dist/uid-library";

export interface useSidebarStateHook {
	sidebarId: string;
	sidebarNaviationId: string;
	mounted: boolean;
	collapsed: boolean;
	setCollapsed: Dispatch<SetStateAction<boolean>>;
	isAddingJourney: boolean;
	setIsAddingJourney: Dispatch<SetStateAction<boolean>>;
	newJourneyInputRef: RefObject<HTMLInputElement>;
	newViewInputRef: RefObject<HTMLInputElement>;
	newActionInputRef: RefObject<HTMLInputElement>;
	isAddingView: boolean;
	setIsAddingView: Dispatch<SetStateAction<boolean>>;
	isAddingAction: boolean;
	setIsAddingAction: Dispatch<SetStateAction<boolean>>;
}

export const useSidebarState = (): useSidebarStateHook => {
	const [mounted, setMounted] = useState<boolean>(false);
	const [collapsed, setCollapsed] = useState<boolean>(false);
	const [isAddingJourney, setIsAddingJourney] = useState<boolean>(false);
	const sidebarId = useUID();
	const sidebarNaviationId = useUID();
	const newJourneyInputRef = useRef<HTMLInputElement>(null);
	const newViewInputRef = useRef<HTMLInputElement>(null);
	const newActionInputRef = useRef<HTMLInputElement>(null);
	const [isAddingView, setIsAddingView] = useState<boolean>(false);
	const [isAddingAction, setIsAddingAction] = useState<boolean>(false);

	useEffect(() => {
		setMounted(true);
	}, []);

	return {
		sidebarId,
		sidebarNaviationId,
		mounted,
		collapsed,
		setCollapsed,
		isAddingJourney,
		setIsAddingJourney,
		newJourneyInputRef,
		newViewInputRef,
		newActionInputRef,
		isAddingView,
		setIsAddingView,
		isAddingAction,
		setIsAddingAction
	}
}