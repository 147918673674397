import { JourneyResource, WriteOnly } from "@ciptex-race/journey";
import { MouseEvent, useCallback, useEffect, useState } from "react";
import { SidebarNavigation, SidebarNavigationDisclosure, SidebarNavigationDisclosureContent, SidebarNavigationDisclosureHeading, SidebarNavigationDisclosureHeadingWrapper, useSidebarNavigationDisclosureState } from "@twilio-paste/core/sidebar"
import { useLinkClickHandler, useParams } from "react-router-dom";
import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/core/button";
import { DirectoryIcon } from "@twilio-paste/icons/esm/DirectoryIcon";
import { ProductSettingsIcon } from "@twilio-paste/icons/esm/ProductSettingsIcon"
import { SidebarNavigationItem } from "./SidebarNavigationItem";
import { SidebarViewInput } from "./SidebarViewInput";
import { useActionContext } from "../../hooks/useActionContext";
import { useConfigContext } from "../../hooks/useConfigContext";
import { useStudioContext } from "../../hooks/useStudioContext";
import { useUIDSeed } from "@twilio-paste/core/uid-library";

const SidebarDisclosure = ({ journey }: { journey: WriteOnly<JourneyResource & { sid: string }> }) => {
	const [isAddingView, setIsAddingView] = useState<boolean>(false);
	const { journeySid, viewName } = useParams();
	const { sidebar } = useStudioContext();
	const { actionInstance, editor } = useActionContext();
	const disclosureState = useSidebarNavigationDisclosureState();
	const seed = useUIDSeed();
	const handleClick = useLinkClickHandler<HTMLButtonElement>(`journey/${journey.sid}#general`);

	useEffect(() => {
		if ((journey.sid === journeySid) && sidebar.isAddingView) {
			disclosureState.show();
			setIsAddingView(true);
		} else {
			setIsAddingView(false);
		}
	}, [disclosureState, journey.sid, journeySid, sidebar.isAddingView]);

	const onDisclosureClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
		if (actionInstance && editor.isChanged) {
			editor.setPendingAction({ type: "navigate", payload: `journey/${journey.sid}#general` });
			editor.setIsAlertOpen(true);
		} else {
			editor.setIsAlertOpen(false);
			handleClick(e);
		}
	}, [actionInstance, editor, handleClick, journey.sid]);

	return (<SidebarNavigationDisclosure key={journey.friendlyName} state={disclosureState}>
		<SidebarNavigationDisclosureHeadingWrapper>
			<SidebarNavigationDisclosureHeading icon={<DirectoryIcon decorative size="sizeIcon30" />}>
				{journey.friendlyName}
			</SidebarNavigationDisclosureHeading>
			<Button element="SIDEBAR_BUTTON" variant="secondary_icon" size="icon_small" onClick={onDisclosureClick}>
				<ProductSettingsIcon size="sizeIcon10" decorative={false} title="Journey Settings" />
			</Button>
		</SidebarNavigationDisclosureHeadingWrapper>
		{isAddingView && <Box>
			<SidebarViewInput />
		</Box>}
		<SidebarNavigationDisclosureContent>
			{Object.entries(journey.definition.views).sort(([keyA], [keyB]) => keyA.localeCompare(keyB)).map(([key]) => (
				<SidebarNavigationItem key={seed(key)} href={`journey/${journey.sid}/${key}`} selected={(journeySid === journey.sid) && (viewName === key)}>
					{key}
				</SidebarNavigationItem>
			))}
		</SidebarNavigationDisclosureContent>
	</SidebarNavigationDisclosure>);
}

export const SidebarJourneyNavigation = () => {
	const { journeysConfig } = useConfigContext();
	const { sidebar } = useStudioContext();
	const seed = useUIDSeed();

	return (<SidebarNavigation aria-label="main" id={sidebar.sidebarNaviationId} hierarchical hideItemsOnCollapse>
		{journeysConfig.config.sort((a, b) => a.friendlyName.localeCompare(b.friendlyName)).map((journey) => (
			<SidebarDisclosure key={seed(journey.sid)} journey={journey} />
		))};
	</SidebarNavigation>);
}