import { CodeIcon } from "@twilio-paste/icons/esm/CodeIcon";
import { SidebarNavigation } from "@twilio-paste/core/sidebar"
import { SidebarNavigationItem } from "./SidebarNavigationItem";
import { useConfigContext } from "../../hooks/useConfigContext";
import { useParams } from "react-router-dom";
import { useStudioContext } from "../../hooks/useStudioContext";

export const SidebarActionNavigation = () => {
	const { actionSid } = useParams();
	const { actionsConfig } = useConfigContext();
	const { sidebar } = useStudioContext();

	return (<>
		<SidebarNavigation aria-label="main" id={sidebar.sidebarNaviationId} hierarchical hideItemsOnCollapse>
			{actionsConfig.config.sort((a, b) => a.friendlyName.localeCompare(b.friendlyName)).map((action) => (
				<SidebarNavigationItem key={action.sid} href={`action/${action.sid}`} icon={<CodeIcon decorative />} selected={action.sid === actionSid}>
					{action.friendlyName}
				</SidebarNavigationItem>
			))};
		</SidebarNavigation>
	</>);
}