import { Badge } from "@twilio-paste/core/badge";
import { Button } from "@twilio-paste/core/button";
import { RaiseHandIcon } from "@twilio-paste/icons/esm/RaiseHandIcon";
import { useCallback } from "react";
import { useStateContext } from "../../hooks/useStateContext";

export const TopbarJourneySave = () => {
	const { saveChanges, isChanged } = useStateContext();

	const onClick = useCallback(() => {
		saveChanges();
	}, [saveChanges]);

	return (<>
		{isChanged && <Badge as="span" variant="decorative20">
			<RaiseHandIcon decorative />
			Unpublished Changes
		</Badge>}
		<Button disabled={!isChanged} variant="primary" onClick={onClick}>Publish</Button>
	</>);
}