import { type ComponentType, type ContextType, ExecutionContextInstance, ExecutionInstance, JourneyInstance, ViewResource, WriteOnly, getFromComponentMap } from "@ciptex-race/journey";
import { Dispatch, ReactElement, SetStateAction, createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useEditor, useEditorStateHook } from "./hooks/useEditor";
import { useNavigate, useParams } from "react-router-dom";
import { FindObjectById } from "../../functions/FindObjectById";
import { GetViewByName } from "../../functions/GetViewByName";
import { JourneyClient } from "@ciptex/journey-sdk";
import { Logger } from "@ciptex-race/logger";
import { UpdateViewComponents } from "../../functions/UpdateViewComponents";
import { useConfigContext } from "../../hooks/useConfigContext";
import { useEditorConfig } from "./hooks/useEditorConfig";
import { useExecutionEvents } from "./hooks/useExecutionEvents";
import { useJourneyEvents } from "./hooks/useJourneyEvents";
import { useLoadJourney } from "./hooks/useLoadJourney";
import { useMessageEvents } from "./hooks/useMessageEvents";
import { useStudioContext } from "../../hooks/useStudioContext";

export type StateContextType = {
	journeyInstance?: JourneyInstance;
	executionInstance?: ExecutionInstance;
	editor: useEditorStateHook;
	selectedComponent?: { component: ComponentType & { _id: string }, contextType: ContextType };
	addView: (journeySid: string, name: string) => Promise<void>;
	saveChanges: () => Promise<void>;
	activeView: {
		name?: string;
		value?: Omit<WriteOnly<ViewResource>, "name">;
	},
	viewNames: string[];
	isDragging: boolean;
	setIsDragging: Dispatch<SetStateAction<boolean>>;
	isChanged: boolean;
	isLoading: boolean;
};

export type SetSelectedComponent = Dispatch<SetStateAction<{
	component: ComponentType & {
		_id: string;
	};
	contextType: ContextType;
} | undefined>>;


export const StateContext = createContext<StateContextType>(null!);

const logger = Logger.getInstance();

export const StateProvider = ({ children }: { children: ReactElement }) => {
	const { journeySid, viewName } = useParams();
	const { journeysConfig, authData } = useConfigContext();
	const { journeyInstance, executionInstance, setJourneyInstance, setExecutionInstance } = useLoadJourney({
		isConfigLoaded: journeysConfig.isLoaded,
		journeysConfig: journeysConfig.configStatic,
		journeySid
	});
	const [selectedComponent, setSelectedComponent] = useState<{ component: ComponentType & { _id: string }, contextType: ContextType }>();
	const [isDragging, setIsDragging] = useState<boolean>(false);
	const [isChanged, setIsChanged] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const activeView = useMemo(() => {
		if (viewName) {
			const name = executionInstance?.activeViewUniqueName
			return {
				name,
				value: name ? journeyInstance?.definition.views[name] : undefined
			}
		} else {
			return { name: undefined, value: undefined }
		}
	}, [executionInstance, journeyInstance, viewName]);

	const viewNames = useMemo(() => {
		if (!journeyInstance?.definition.views) {
			return [];
		}

		return Object.keys(journeyInstance.definition.views);
	}, [journeyInstance]);

	const editorsConfigs = useEditorConfig({ viewNames });
	useJourneyEvents({ journey: journeyInstance, activeView, setIsChanged, setSelectedComponent, setJourneyInstance, setJourneysConfig: journeysConfig.setConfig });
	useMessageEvents({ journey: journeyInstance, activeView, setSelectedComponent, setJourneyInstance });
	useExecutionEvents({ execution: executionInstance, journeySid, setSelectedComponent, setExecutionInstance });

	const { toaster } = useStudioContext();
	const navigate = useNavigate();

	const onEditorContextUpdated = useCallback(async (instance: ExecutionContextInstance) => {
		logger.debug("StateProvider", "onEditorContextUpdated", instance.sid);
		const { _id, _type, ...rest } = instance.contextData;
		const { friendlyName } = getFromComponentMap(_type);

		if (!journeyInstance || !activeView?.name || !activeView.value) {
			return;
		}

		try {
			const { components = [] } = activeView?.value || {};
			if (_type === "view") {
				//
			} else {
				const c = FindObjectById(components, _id);
				c.props = { ...rest };
			}

			await UpdateViewComponents(journeyInstance, {
				viewName: activeView.name,
				components
			});

			toaster.push({
				variant: "success",
				message: `${friendlyName} saved`,
				dismissAfter: 2000
			});
		} catch (error) {
			toaster.push({
				variant: "error",
				message: `Unable to save changes to ${friendlyName}`,
				dismissAfter: 2000
			});
		}
	}, [activeView.name, activeView.value, journeyInstance, toaster]);

	const editor = useEditor({ editorsConfigs, selectedComponent, onContextUpdated: onEditorContextUpdated });

	const addView = useCallback(async (journeySid: string, name: string) => {
		logger.debug("StateProvider", `addView ${name} to ${journeySid}`);

		if (!journeyInstance || !name) {
			return;
		}

		const instance = await UpdateViewComponents(journeyInstance, {
			viewName: name,
			components: []
		});

		navigate(`journey/${instance.sid}/${name}`, { replace: true });

		toaster.push({
			variant: "success",
			message: `View ${name} created`,
			dismissAfter: 2000
		});
	}, [journeyInstance, navigate, toaster]);

	const changeActiveView = useCallback(async (name?: string) => {
		logger.debug("useJourney", "changeActiveView", name);
		if (!journeyInstance || !executionInstance) {
			logger.warn("useJourney", "changeActiveView", "No Journey Instance or Execution");
			return;
		}

		if (!name) {
			await executionInstance.update({
				activeViewSid: undefined
			});
			return;
		}

		const view = await GetViewByName(journeyInstance, { viewName: name });
		if (view) {
			if (executionInstance.activeViewSid !== view.sid) {
				logger.debug("useJourney", "changeActiveView", `Changing view to ${view.sid}`);
				await executionInstance.update({
					activeViewSid: view.sid
				});
			}
		} else {
			logger.warn("useJourney", "changeActiveView", "View Not Found");
			navigate(`/studio/journey/${journeyInstance.sid}#general`, { replace: true });
		}
	}, [executionInstance, journeyInstance, navigate]);

	const saveChanges = useCallback(async () => {
		try {
			if (!authData || !journeySid || !journeyInstance) {
				return;
			}
			setIsLoading(true);
			const client = new JourneyClient({
				accountSid: authData.accountSid,
				token: authData.user.token
			});

			await client.journey.update({
				friendlyName: journeyInstance.friendlyName,
				definition: journeyInstance.definition,
				props: journeyInstance.props
			}, { journeySid });

			toaster.push({
				variant: "success",
				message: "Journey Published",
				dismissAfter: 2000
			});
			setIsChanged(false);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	}, [authData, journeyInstance, journeySid, toaster]);

	useEffect(() => {
		// If any of viewName, journeyInstance or executionInstance have no loaded then exit
		if (!journeyInstance?.sid || !executionInstance?.journeySid) {
			return;
		}

		// If the journeyInstance or executionInstance are stale due to nav then exit
		if ((journeyInstance?.sid !== journeySid) || (executionInstance?.journeySid !== journeySid)) {
			return;
		}

		// Finally check if we even need to navigate
		if (executionInstance?.activeViewUniqueName === viewName) {
			return;
		}

		if (viewName) {
			changeActiveView(viewName);
		} else {
			setSelectedComponent(undefined);
			setIsDragging(false);
			// console.log("MD Load Journey!");
		}
	}, [changeActiveView, executionInstance?.activeViewUniqueName, executionInstance?.journeySid, journeyInstance?.sid, journeySid, viewName]);

	return <StateContext.Provider value={{
		isDragging,
		isChanged,
		isLoading,
		setIsDragging,
		addView,
		saveChanges,
		editor,
		journeyInstance,
		executionInstance,
		activeView,
		selectedComponent,
		viewNames
	}}>{children}</StateContext.Provider>;
}